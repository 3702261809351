.nav {
    background-color: azure;
    color: orange;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    gap: 2rem;
    padding: 0 1rem;
}
.site-title {
    font-size:  2rem;
}

.nav div {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    gap: 1rem;
}
.nav a {
    color:  inherit;
    text-decoration: none;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0.25rem;
}

.nav div.active {
    background-color: azure;
    list-style: none;
}
.navbar-nav {
    list-style: none;
}
.nav-group {

    display: inherit;

}
.nav-element{
    background-color: azure;
    color: orange;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    gap: 2rem;
    padding: 0 1rem;
}
.nav-element:hover {
    background-color: #777;
}
.nav-active {
    background-color: azure;
    list-style: none;
}
.nav-active:hover {
    background-color: yellow;
    list-style: none;
}

.nav-info {
    align-items: center;
}