{
  box-sizing:border-box;
}
body {
  margin: 0;
}
.App {
  display: flex;
  flex-direction: column;
}
.Main{
  align-self: center;
}
.invisible{
  display: none;
}
.center {
  align-self: center;
}
.input {
  width: 80%;
}
/*
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
*/

/*
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
*/

/*.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}*/

.App-link {
  color: #61dafb;
}

.Container-stretch {
  align-self: stretch;
}
.outer {
  align-items: stretch;
  display: flex;

}

.inner {
  align-items: center;
  display: flex;
}

.hidden {
  display: none;
}

.
showing {
  display: block;
  animation: fadeInUp 400ms;
}


.Register-title {
  background-color: #a87c34;
  align-self: stretch;
}

.lieferadresse {
  font-size: small;
}

.Accordion-root {
  display: flex;
  flex-direction: column ;
  align-items: center;
  min-width: 50rem;
  background-color: salmon;
}
.MuiCollapse-rootq {
  display: contents;
  transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  min-height: 0px;
  height: auto;
  background-color: salmon;
  transition-duration: 225ms;
}

.MuiCollapse-root {
  width: 100%;
}

.MuiAccordionSummary-root {
  width: 100%;
  align-content: center;

}


.button-container  {
  display: contents;
}
.MuiButtonBase-root {
  background-color: orange;
}

.MuiButton-root {
  background-color: orange;
}
.fab-button {
  width: 100px;
}
.errorMessage {
  color: red;
  text-align: center;
}
.maxheigthnone {
  max-height: none;
}

/*@keyframes App-logo-spin {*/
/*  from {*/
/*    transform: rotate(0deg);*/
/*  }*/
/*  to {*/
/*    transform: rotate(360deg);*/
/*  }*/
/*}*/

.selected1 {
  backgroundColor: "pink";
  color: #a47134;
}
  .hover1 {
           backgroundColor: "tomato";
         }


  .centerText {
    text-align: center;
  }

.Register-col-flex{
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  margin-top: 10px;
  width: 100%;
}

.Register-col{
  flex-direction: row;
  margin-bottom: 10px;
  margin-top: 10px;
  width: 100%;
}
.Register-row{
  display: flex;
  flex-direction: column;
  margin: 1rem;
  width: 100%;
  align-items: stretch;
}
.mtop05 {
  margin-top: -0.5rem;
}
.Register-row-container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
   margin-top: 20px;
}

.salmon {
  color: salmon;
}


.just-even {
  align-items: center;
  justify-content: space-evenly;
}
.Register-row > div {
  flex: 1 1 auto;
}

.Accordion-original
{
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 50rem;
  background-color: salmon;
  color: rgba(0, 0, 0, 0.87);
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12);
  position: relative;
  transition: margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  overflow-anchor: none;
  border-radius: 0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.mainx {
  font-weight: bold;
}


.Table
{
  display: table;
}
.Row
{
  display: table-row;
  background-color: bisque;
}
.Cell
{
  display: table-cell;
  border-width: 3px;
  border-color: #a87c34;
  margin: 5px;
}
.RowHeader
{
  display: table-row;
  background-color: salmon;

}
.CellHeader
{
  display: table-cell;
}

.day-header {
  font-weight: bold;
  font-size: medium;
}
.rezept-number {
  width: 6%;
}
.rezept-description {
  width: max-content;
}
.rezept-price {
  width:13%;
  font-weight: bolder;
}
tr.spaceUnder>td {
  padding-bottom: 5em;
}

.w80 {
  width: 80%;
  margin: 10rem;
}
.alternate {
  background-color: #dddddd;
}
table tr:hover{
  background: #f1f1f1;
}

