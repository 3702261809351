.Header-main{
    background-color: white;
    min-height: 10vh;
    max-height: 15vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    text-align: center;
}
.Header-logo {
    width: 10vh;
    pointer-events: none;
}
.Header-logo2 {
    width: 10vh;
    pointer-events: none;
    margin-left: 5rem;
    margin-top: 2rem;
}
.Header-h1 {
    color: orange;
    margin-left: 10pt;
    align-content: center;
}